import React, { useState, memo } from 'react';

const CommunityProfilePictureMemo = memo(({ commneedsimg }) => {
    const [imageError, setImageError] = useState(false);
    const imageUrl = commneedsimg?.community_profile_img_url;
    const firstLetter = commneedsimg?.community_name?.charAt(0).toUpperCase();

    return (
      <div className="messageProfilePicture" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {imageUrl && !imageError ? (
          <img
            src={imageUrl}
            alt={commneedsimg?.community_name}
            onError={() => setImageError(true)}
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
          />
        ) : (
          <div className="messageProfilePicture" style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px',
            color: '#333',
          }}>
            {firstLetter}
          </div>
        )}
      </div>
    );
});

export const CommunityProfilePicture = CommunityProfilePictureMemo;
